@import url('legendsSlider.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: DejaVu-Sans;
    src: url('./assets/fonts/DejaVuSans.ttf');
}

@font-face {
    font-family: DejaVu-Sans-Bold;
    src: url('./assets/fonts/DejaVuSans-Bold.ttf');
}

* {
    font-family: DejaVu-Sans, sans-serif;
}

body {
    overflow-x: hidden;
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(85, 85, 85, 0.7); /* Color with transparency */
    border-radius: 3px; /* Rounded corners of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(119, 119, 119, 0.7); /* Color with transparency when hovered */
}

/* Track */
::-webkit-scrollbar-track {
    background-color: rgba(249, 249, 249, 0.5); /* Color with transparency */
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
    background-color: rgba(234, 234, 234, 0.5); /* Color with transparency when hovered */
}

/* Corner */
::-webkit-scrollbar-corner {
    background-color: transparent; /* Color of the scrollbar corner */
}

.disable-scrollbar::-webkit-scrollbar {
    display: none;
}

.bold {
    font-family: DejaVu-Sans-Bold, sans-serif;
}

.section-title {
    font-family: DejaVu-Sans, sans-serif;
}

.theatres-slider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 750px;
    margin-bottom: 50px;
}

.theatres-slider .swiper-pagination {

}

.theatres-slider .swiper-pagination-bullet {
    background: #D8D8D8;
    width: 10px !important;
    height: 10px !important;
    opacity: 1 !important;
    margin-right: 20px !important;
}

.theatres-slider .slider-controller .slider-arrow:after {
    display: none;
}

.theatres-slider .slider-controller .slider-arrow {
    width: 50px !important;
}

.theatres-slider .swiper-pagination-bullet-active {
    background: #F1BA51 !important;
}

.theatres-slider .slider-controller {
    position: absolute;
    top: 35%;
    width: 100%;
}

.hero-slider .swiper-pagination-bullets {
    position: absolute !important;
    z-index: 9999;
    left: 50% !important;
    transform: translateX(-50%);
    bottom: 10% !important;
    max-width: 1280px;
    display: flex;
    justify-content: start;
    padding: 0 16px;
}

@media (min-width: 1440px) {
    .hero-slider .swiper-pagination-bullets {
        bottom: 13.5% !important;
    }
}

@media (max-width: 768px) {
    .section-title {
        font-family: DejaVu-Sans-Bold, sans-serif;
    }
}

.hero-slider .swiper-pagination-bullet {
    background: #ACA4AA !important;
    width: 15px !important;
    height: 15px !important;
    opacity: 1 !important;
    margin-right: 20px !important;
}
.hero-slider .swiper-pagination-bullet-active {
    background: #fff !important;
}

/* pagination */
.MuiPagination-ul .MuiPaginationItem-root {
    font-size: 20px !important;
    color: #44002C;
}

.MuiPagination-ul .Mui-selected {
    background: none !important;
    color: #F1BA51 !important;
}