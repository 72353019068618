:root {
    --primary: #6a59ff;
    --white: #ffffff;
    --bg: #f5f5f5;
}

.swiper_legends {
    padding: 0 100px;
    max-width: 1440px;
    margin: 0 auto;
    overflow: visible !important;
}

.swiper_legends .swiper-wrapper {
    margin: 0 auto;
}

.swiper_legends * {
    user-select: none;
}

.swiper_legends .swiper-slide {
    width: 400px;
    height: 520px;
    position: relative;
}

.swiper_legends .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper_legends .swiper-slide-shadow-left,
.swiper_legends .swiper-slide-shadow-right {
    display: none;
}

.swiper_legends .slider-controller {

}

.swiper_legends .slider-image {
    width: 100%;
    height: 100%;
}

.swiper_legends .slider-controller .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper_legends .slider-controller .slider-arrow {
    background: transparent;
}
.swiper_legends .slider-controller .slider-arrow:after {
   display: none;
}
.swiper_legends .slider-controller .swiper-button-next {
   transform: rotate(180deg) !important;
}

@media (max-width: 768px) {
    .swiper_legends .swiper-slide {
        width: 200px;
        height: 228px;
    }

    .swiper_legends .slider-controller {
        display: none;
    }
}